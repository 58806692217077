<template>
	<div>
		<emb-data-table-without-filter :resource="resource">
			<tr slot="heading">
				<th>CANAL</th>
				<th>N°</th>
				<th>FECHA Y HORA</th>
				<th>CLIENTE</th>
				<th>REPARTIDOR</th>
				<th class="text-center">ESTADO</th>
				<th>DIRECCIÓN DE ENTREGA</th>
				<th class="text-right">TOTAL</th>
				<th class="text-right">ACCIONES</th>
			</tr>
			<tr slot-scope="{ index, row }" :key="index">
				<td>
					<v-icon v-if="row.channel_id == '01'" large color="info">mdi-album</v-icon>
					<v-icon v-if="row.channel_id == '02'" large color="success"
						>mdi-whatsapp</v-icon
					>
					<v-icon v-if="row.channel_id == '04'" large color="accent">mdi-phone</v-icon>
				</td>
				<td>{{ index }}</td>
				<td>{{ row.date_of_issue + ' ' + row.time_of_issue }}</td>
				<td>{{ row.customer }}</td>
				<td>{{ row.motorised }}</td>

				<td class="text-center">
					<v-chip color="red" text-color="white" x-small v-if="row.state == '01'">
						{{ row.state_description }}
					</v-chip>
					<v-chip color="success" text-color="white" x-small v-if="row.state == '02'">
						{{ row.state_description }}
					</v-chip>
					<v-chip color="info" text-color="white" x-small v-if="row.state == '04'">
						{{ row.state_description }}
					</v-chip>
				</td>
				<td>{{ row.shipping.location.address }}</td>
				<td class="text-right">{{ row.currency_type }} {{ row.total }}</td>
				<td class="text-right">
					<template v-if="row.state == '04'">
						<!-- <v-btn
							color="accent"
							class="ma-1"
							fab
							x-small
							dark
							:to="{ name: 'DeliveryPayment', params: { id: row.id } }"
						>
							<v-icon>mdi-exit-to-app</v-icon>
						</v-btn> -->

						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn
									color="accent"
									class="ma-1"
									fab
									x-small
									dark
									:to="{ name: 'DeliveryPayment', params: { id: row.id } }"
									v-on="on"
								>
									<v-icon>mdi-exit-to-app</v-icon>
								</v-btn>
							</template>
							<span>Cobrar</span>
						</v-tooltip>
					</template>
					<!-- <v-btn
						color="accent"
						class="ma-1"
						fab
						x-small
						dark
						:to="{ name: 'SaleReceivable', params: { id: row.id } }"
					>
						<v-icon>mdi-exit-to-app</v-icon>
					</v-btn> -->
				</td>
			</tr>
		</emb-data-table-without-filter>

		<!-- </div> -->

		<emb-location-dialog
			:showLocationDialog.sync="showLocationDialog"
			:isRouteMenu="true"
			:lat="lat"
			:lng="lng"
			:draggable="false"
			:autoAdd="false"
			:item="null"
		>
		</emb-location-dialog>

		<!-- <details-form :showDetailsDialog.sync="showDetailsDialog" :recordId="recordId">
		</details-form> -->
	</div>
</template>

<script>
// import DetailsForm from './Component/Details';
import { deletable } from 'Mixins/deletable';
import { confirm } from 'Mixins/confirm';
// import OrdersCreate from './Create';

export default {
	data() {
		return {
			showLocationDialog: false,
			showRejectedDialog: false,
			showDetailsDialog: false,
			showRegisterDialog: false,
			showSendDialog: false,
			loadingSubmit: false,
			resource: 'delivery-receivables',
			showDialog: false,
			recordId: null,
			records: [],
			lat: null,
			lng: null,
		};
	},
	mixins: [deletable, confirm],
	// components: {
	// 	DetailsForm,
	// },
	methods: {
		update() {
			location.reload();
			// this.$eventHub.$emit('reloadDataOrders');
		},
		seeLocation(location) {
			this.lat = location.latitude;
			this.lng = location.longitude;
			this.showLocationDialog = true;
		},
		confirmed(recordId) {
			this.confirm().then(() => {
				this.$http.post(`/${this.resource}/confirmed/${recordId}`).then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						// this.$eventHub.$emit('reloadDataOrders', this.resource);
						location.reload();
					} else {
						this.$message.error(response.data.message);
					}
				});
			});
		},
		showDetails(recordId) {
			this.recordId = recordId;
			this.showDetailsDialog = true;
		},
		rejected(recordId) {
			this.recordId = recordId;
			this.showRejectedDialog = true;
		},
		send(recordId) {
			this.recordId = recordId;
			this.showSendDialog = true;

			// this.confirm().then(() => {
			//     this.$http.post(`/${this.resource}/send/${recordId}`).then(response => {
			//         if (response.data.success) {
			//             this.$message.success(response.data.message);
			//             this.$eventHub.$emit('reloadDataOrders')
			//         } else {
			//             this.$message.error(response.data.message);
			//         }
			//     })
			// })
		},
		deliver(recordId) {
			this.confirm().then(() => {
				this.$http.post(`/${this.resource}/deliver/${recordId}`).then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.$eventHub.$emit('reloadDataOrders');
					} else {
						this.$message.error(response.data.message);
					}
				});
			});
		},
		printOrder(recordId) {
			this.$http
				.get(`/${this.resource}/print/${recordId}`)
				.then((response) => {
					if (response.data.success) {
						let zones = response.data.zones;
						let pc_ip = response.data.pc_ip;
						for (let index = 0; index < zones.length; ++index) {
							let data = JSON.stringify(zones[index]);
							// let url = `https://${pc_ip}:8080/print-api/print/command`;
							let url = `https://${pc_ip}/print-api/print/command`;
							// let url = `https://print-api.test/print/command`;

							fetch(url, { method: 'POST', body: data })
								.then((dataWrappedByPromise) => dataWrappedByPromise.json())
								.then((data) => {
									if (data.success) {
										this.$message.success(data.message);
									} else {
										this.$message.error(data.message);
									}
								})
								.catch((error) => {
									console.error('Error: ', error);
								});
						}
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					this.$message.error(error.response.data.message);
				});
		},
		clickDelete(id) {
			this.destroy(`/${this.resource}/${id}`).then(() =>
				this.$eventHub.$emit('reloadData', this.resource)
			);
		},
	},
};
</script>
